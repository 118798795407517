.credit-score {
  $ref: &;

  &__grid {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3px;
    padding: 3px;
    color: $gray-500;
    font-size: 12px;
    line-height: 18px; /* 150% */
    font-weight: $font-weight-bold;
    text-align: center;
    background-color: $gray-100;
    border: 1px solid $gray-200;
    &:first-child {
      border-radius: 8px 0 0 8px;
    }
    &:last-child {
      border-radius: 0 8px 8px 0;
    }
    &:not(:first-child) {
      margin-left: -1px;
    }
    &--active {
      max-width: 28.81px;
      margin-top: 0;
      padding: 3px 4px;
      border-radius: 4px;
      color: white;
      font-size: 14px;
      line-height: 28px;
      background-color: $teal;
      z-index: 1;
      border-radius: 8px !important;

      &#{$ref}__a {
        border-color: $credit-score-a;
        color: white;
        background-color: $credit-score-a;
      }
      &#{$ref}__b {
        border-color: $credit-score-b;
        color: white;
        background-color: $credit-score-b;
      }
      &#{$ref}__c {
        border-color: $credit-score-c;
        color: white;
        background-color: $credit-score-c;
      }
      &#{$ref}__d {
        border-color: $credit-score-d;
        color: white;
        background-color: $credit-score-d;
      }
      &#{$ref}__e {
        border-color: $credit-score-e;
        color: white;
        background-color: $credit-score-e;
      }
    }
    @include media-breakpoint-up(sm) {
      padding: 3px 6px;
      font-size: 12px;
      line-height: 18px; /* 150% */
      &--active {
        padding: 3px 8px;
        font-size: 18px;
        line-height: 28px; /* 155.556% */
      }
    }
  }
}
